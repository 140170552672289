@use 'sass:map';
@use '@angular/material' as mat;

@mixin color($theme) {
  .sqep-button {
    --border-radius: 6px;
    color: mat.get-theme-color($theme, primary, 30) !important;
    background: mat.get-theme-color(
      $theme,
      primary,
      100
    ) !important; // remove at some point
    border: 1px solid mat.get-theme-color($theme, primary, 30);
    border-radius: var(--border-radius);
    margin-right: 5px;
    height: 40px;
    padding: 8px;

    &:hover {
      background: mat.get-theme-color($theme, primary, 30) !important;
      color: mat.get-theme-color($theme, primary, 100) !important;
    }

    &:disabled {
      opacity: 0.65;
    }
  }
  .sqep-button-warn {
    --border-radius: 6px;
    color: mat.get-theme-color($theme, error);
    background: mat.get-theme-color(
      $theme,
      error,
      50
    ) !important; // remove at some point
    border: 1px solid mat.get-theme-color($theme, error, 50);
    border-radius: var(--border-radius);
    margin-right: 5px;
    padding: 8px;
    height: 40px;

    &:disabled {
      opacity: 0.65;
    }
  }
  .sqep-button-tertiary {
    --border-radius: 6px;
    color: mat.get-theme-color($theme, on-primary-container);
    background: mat.get-theme-color(
      $theme,
      secondary,
      40
    ) !important; // remove at some point
    border-color: mat.get-theme-color($theme, primary);
    border-radius: var(--border-radius);
    margin-right: 5px;
    padding: 8px;
    height: 40px;
  }
}

@mixin typography($theme) {
  .sqep-button-font {
    font: mat.get-theme-typography($theme, 'body-medium');
    font-family: Roboto, 'Helvetica Neue', sans-serif;
    letter-spacing: mat.get-theme-typography(
      $theme,
      'body-medium',
      'letter-spacing'
    );
  }
}

@mixin theme($theme) {
  @include color($theme);
  @include typography($theme);
}
