@use 'sass:map';
@use '@angular/material' as mat;

@mixin color($theme) {
  .sqep-paginator {
    color: mat.get-theme-color($theme, primary, 30);
    background: mat.get-theme-color($theme, primary, 100);
    border-top: 1px solid #264148;
  }
}

@mixin typography($theme) {
  .sqep-paginator-font {
    font: mat.get-theme-typography($theme, 'body-medium') !important;
    font-family: Roboto, 'Helvetica Neue', sans-serif;
    letter-spacing: mat.get-theme-typography(
      $theme,
      'body-medium',
      'letter-spacing'
    );
  }
}

@mixin theme($theme) {
  @include color($theme);
  @include typography($theme);
}
