$esqep-spinner-height: 0.5rem;

$box-shadow: 0 0.5rem 1rem rgba(black, 0.15);

$top-nav-height: 60px;

$side-nav-width: 230px;

$footer-height: 100px;

$offcanvas-horizontal-width: 230px; // overiding bootstrap offcanvas width
