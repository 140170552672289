@use 'sass:map';
@use '@angular/material' as mat;

@mixin color($theme) {
  .sqep-search {
    color: mat.get-theme-color($theme, primary, 100);
    background: mat.get-theme-color($theme, primary, 100);
  }
}

@mixin typography($theme) {
  .sqep-search-font {
    font: mat.get-theme-typography($theme, 'body-medium');
    font-family: Roboto, 'Helvetica Neue', sans-serif;
    letter-spacing: mat.get-theme-typography(
      $theme,
      'body-medium',
      'letter-spacing'
    );
  }
}

@mixin theme($theme) {
  @include color($theme);
  @include typography($theme);
}
