$sqeptech-dark: #264148;
$sqeptech-offWhite: #f5f5f5;
$sqeptech-button: $sqeptech-dark;

$esqep-purple: #90439a;
$esqep-cyan: #17b9a7;
$esqep-red: #ef432e;
$esqep-orange: #f7931d;

$esqep-purple-100: rgba(144, 67, 154, 1);
$esqep-purple-90: rgba(144, 67, 154, 0.9);
$esqep-purple-80: rgba(144, 67, 154, 0.8);
$esqep-purple-70: rgba(144, 67, 154, 0.7);
$esqep-purple-60: rgba(144, 67, 154, 0.6);
$esqep-purple-50: rgba(144, 67, 154, 0.5);
$esqep-purple-40: rgba(144, 67, 154, 0.4);
$esqep-purple-30: rgba(144, 67, 154, 0.3);
$esqep-purple-20: rgba(144, 67, 154, 0.2);
$esqep-purple-10: rgba(144, 67, 154, 0.1);

$reassured-blue: #1870a0;
$reassured-orange: #f68a1f;

$gmfrs-red: #9b0d27;
$gmfrs-black: #000000;
$mfrs-blue: #174079;
$getinge-blue: #18274a;
$pepper-red: #df0024;

$esqep-expn-dark-blue: rgb(0, 69, 143);

$light: #f8f9fa;
$yellow: #ffc107;
$white: #ffffff;
$cyan: #0dcaf0;
$green: #198754;
$grey-700: #616161;

// $primary : $esqep-purple;
// $secondary: $esqep-cyan;

$primary: #0d6efd;
$secondary: #6c757d;
$success: #198754;
