@use '@angular/material' as mat;
@use '../m3-theme.scss';
@use './styles/themes/all' as themes;
@use './styles/sizes';
@use './styles/components';

@include mat.elevation-classes();
@include mat.app-background();

:root {
  h6,
  .h6,
  h5,
  .h5,
  h4,
  .h4,
  h3,
  .h3,
  h2,
  .h2,
  h1,
  .h1 {
    color: #000;
  }

  // overiding default colors

  .mdc-linear-progress {
    height: 10px;
  }

  .mat-mdc-header-row {
    color: #000;
  }
  .mat-mdc-row {
    color: #000;
  }

  .mat-divider {
    border-top-color: lightgray;
    border-top-width: thin;
  }

  .mdc-text-field--outlined:not(.mdc-text-field--disabled)
    .mdc-text-field__input {
    font-family: Roboto, 'Helvetica Neue';
    color: #000;
  }

  .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled)
    .mdc-list-item__primary-text {
    font-family: Roboto, 'Helvetica Neue';
  }
  .mat-mdc-option .mdc-list-item__primary-text {
    font-family: Roboto, 'Helvetica Neue';
    color: #000;
  }
  .mat-mdc-card-title {
    --mat-card-title-text-font: font-family: Roboto, 'Helvetica Neue',
      sans-serif;
  }
  .mat-mdc-tab-header-pagination-chevron {
    border-color: #000;
  }
  .mat-mdc-tab.mdc-tab--active .mdc-tab__text-label {
    color: #000;
  }

  .tab-heading {
    color: #264148;
    font-weight: 400;
  }

  .tab-subheading {
    color: #264148;
    font-weight: 400;
    margin-bottom: 16px;
    font-size: 0.875rem;
  }
  .mat-mdc-tab:hover .mdc-tab__text-label {
    color: #000;
  }
  .mat-mdc-tab .mdc-tab__text-label {
    color: #000;
  }
  .mat-mdc-autocomplete-panel {
    --mat-autocomplete-background-color: #fff !important;
  }
  .mat-mdc-card-outlined {
    background-color: #fff !important;
  }
  .mat-expansion-panel:not([class*='mat-elevation-z']) {
    box-shadow: none;
  }
  --mat-select-panel-background-color: #fff !important;
  --mat-option-selected-state-layer-color: #264148 !important;
  --mat-option-selected-state-label-text-color: #fff !important;

  .mat-mdc-card {
    --mdc-elevated-card-container-color: #fff !important
  ;
  }
  .mdc-text-field--outlined .mdc-floating-label {
    font-size: 0.875rem !important;
  }
  .mat-mdc-option {
    font-size: 0.875rem !important;
  }
  .mat-mdc-select {
    color: #000;
    font-size: 0.875rem !important;
  }

  .mat-mdc-tab-label-container {
    border: none !important;
  }

  .mat-mdc-tab.mdc-tab {
    padding-top: 16px !important;
  }

  .claim-skill-filters .mat-expansion-panel-content .mat-expansion-panel-body {
    padding: 8px 0 !important;
  }

  .sqep-datepicker {
    .mat-mdc-icon-button {
      padding: 8px !important;
      height: 0 !important;
    }
  }

  .row-hover {
    cursor: pointer;
  }

  .mat-mdc-card-header {
    font-size: 1rem !important;
    font-weight: 500 !important;
  }

  .mat-mdc-card-title {
    font-size: 1.375rem !important;
  }

  .error-tooltip {
    .mdc-tooltip__surface {
      font-size: 1rem !important;
      color: white !important;
      background-color: black !important;
    }
  }

  .mdc-text-field--outlined.mdc-text-field--disabled {
    background-color: #e9ecef !important;
  }

  .modal-header.bg-dark.text-light .modal-title {
    font-weight: 400;
    color: white !important;
  }

  .mat-button-toggle-label-content {
    line-height: 32px !important;
    color: #000;
  }

  .mat-button-toggle-appearance-standard.mat-button-toggle-checked {
    background-color: #264148 !important;

    .mat-button-toggle-label-content {
      color: white !important;
    }
  }

  .mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
  .mat-button-toggle-group-appearance-standard {
    border-radius: 6px;
  }

  // @include mat.all-component-themes(themes.$my-app-theme);
  @include components.theme(themes.$my-app-theme);
  @include mat.elevation-classes();
  @include mat.app-background();
  @include mat.autocomplete-theme(themes.$my-app-theme);
  @include mat.button-theme(themes.$my-app-theme);
  @include mat.form-field-theme(themes.$my-app-theme);
  @include mat.form-field-density(themes.$my-app-compact-theme);
  @include mat.input-theme(themes.$my-app-theme);
  @include mat.select-theme(themes.$my-app-theme);
  @include mat.tabs-theme(themes.$my-app-theme);
  @include mat.expansion-theme(themes.$my-app-theme);
  @include mat.table-theme(themes.$my-app-theme);
  @include mat.paginator-theme(themes.$my-app-theme);
  @include mat.card-theme(themes.$my-app-theme);
  @include mat.checkbox-theme(themes.$my-app-theme);
  @include mat.datepicker-theme(themes.$my-app-theme);
  @include mat.chips-theme(themes.$my-app-theme);
  @include mat.badge-theme(themes.$my-app-theme);
  @include mat.tooltip-theme(themes.$my-app-theme);
  @include mat.button-toggle-theme(themes.$my-app-theme);
  @include components.typography(themes.$my-app-theme);
}

html {
  height: 100%;
}

.mat-mdc-progress-spinner {
  --mdc-circular-progress-active-indicator-color: #264148;
}

body {
  margin: 0;
  font-size: 0.875rem;
}

.form-check-label {
  font-size: 1rem;
}
