@use 'sass:map';
@use '@angular/material' as mat;

@mixin color($theme) {
  .sqep-table {
    color: mat.get-theme-color($theme, primary, 30);
    .mat-mdc-cell,
    .mat-mdc-header-row,
    th {
      border-bottom-color: #dee2e6 !important;
    }

    .mat-sort-header-content {
      color: rgb(33, 37, 41);
      font-weight: 500;
    }

    background: mat.get-theme-color($theme, primary, 100);
  }
}

@mixin typography($theme) {
  .sqep-table-font {
    font: mat.get-theme-typography($theme, 'body-medium');
    font-family: Roboto, 'Helvetica Neue', sans-serif;
    letter-spacing: mat.get-theme-typography(
      $theme,
      'body-medium',
      'letter-spacing'
    );
  }
}

@mixin theme($theme) {
  @include color($theme);
  @include typography($theme);
}
