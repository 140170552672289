@use 'sass:map';
@use '@angular/material' as mat;

@mixin color($theme) {
  .sqep-radio {
    color: #264148;
    // --border-radius: 0.375rem;
    // color: mat.get-theme-color($theme, primary, 10);
    // background: mat.get-theme-color($theme, primary, 30) !important; // remove at some point
    // border-color: mat.get-theme-color($theme, primary);
    // border-radius:  var(--border-radius);
    // margin-right: 0.313rem;
  }
}

@mixin typography($theme) {
  .sqep-radio {
    font: mat.get-theme-typography($theme, 'body-medium');
    font-family: Roboto, 'Helvetica Neue', sans-serif;
    letter-spacing: mat.get-theme-typography(
      $theme,
      'body-medium',
      'letter-spacing'
    );
    width: 100%;
  }
}

@mixin theme($theme) {
  @include color($theme);
  @include typography($theme);
}
