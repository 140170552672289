@import 'colours';
@import 'variables';
@import '../../node_modules/bootstrap/scss/functions';
@import '../../node_modules/bootstrap/scss/variables';
@import '../../node_modules/bootstrap/scss/variables-dark';
@import '../../node_modules/bootstrap/scss/maps';
@import '../../node_modules/bootstrap/scss/mixins';
@import '../../node_modules/bootstrap/scss/utilities';
@import '../../node_modules/bootstrap/scss/root';
@import '../../node_modules/bootstrap/scss/reboot';

// Optional Bootstrap components here
@import '../../node_modules/bootstrap/scss/alert';
@import '../../node_modules/bootstrap/scss/buttons';
@import '../../node_modules/bootstrap/scss/card';
@import '../../node_modules/bootstrap/scss/containers';
@import '../../node_modules/bootstrap/scss/dropdown';
@import '../../node_modules/bootstrap/scss/forms';
@import '../../node_modules/bootstrap/scss/grid';
@import '../../node_modules/bootstrap/scss/images';
@import '../../node_modules/bootstrap/scss/offcanvas';
@import '../../node_modules/bootstrap/scss/modal';
@import '../../node_modules/bootstrap/scss/progress';
@import '../../node_modules/bootstrap/scss/spinners';
@import '../../node_modules/bootstrap/scss/tables';
@import '../../node_modules/bootstrap/scss/grid';
@import '../../node_modules/bootstrap/scss/close';
@import '../../node_modules/bootstrap/scss/list-group';
@import '../../node_modules/bootstrap/scss/type';
@import '../../node_modules/bootstrap/scss/nav';
@import '../../node_modules/bootstrap/scss/transitions';
@import '../../node_modules/bootstrap/scss/badge';
@import '../../node_modules/bootstrap/scss/accordion';
@import '../../node_modules/bootstrap/scss/pagination';
@import '../../node_modules/bootstrap/scss/navbar';
@import '../../node_modules/bootstrap/scss/toasts';
@import '../../node_modules/bootstrap/scss/tooltip';
@import '../../node_modules/bootstrap/scss/breadcrumb';
@import '../../node_modules/bootstrap/scss/popover';

// Helpers
@import '../../node_modules/bootstrap/scss/helpers';
// Utilities
@import '../../node_modules/bootstrap/scss/utilities/api';
@import '../../node_modules/@ng-select/ng-select/themes/default.theme.css';

@for $i from 1 through 20 {
  .w-#{$i * 5} {
    width: $i * 5%;
  }
}

* {
  font-family: Roboto, 'Helvetica Neue', sans-serif;
  font-weight: 400;
}

body {
  margin: 0;
  background-color: $sqeptech-offWhite;
  font-size: 14px;
}

th {
  font-weight: 500 !important;
}

.fw-bold {
  font-weight: 500 !important;
}

.badge {
  --bs-badge-font-weight: 500;
}

.spinner-border.esqep-loading-spinner {
  border-top-color: $esqep-purple;
  border-right-color: $esqep-cyan;
  border-bottom-color: $esqep-red;
  border-left-color: $esqep-orange;
  border-width: 1rem;
  animation: spinner-border 1s linear infinite;
  height: 5rem;
  width: 5rem;
}

.spinner-border.esqep-loading-spinner-small {
  border-top-color: $esqep-purple;
  border-right-color: $esqep-cyan;
  border-bottom-color: $esqep-red;
  border-left-color: $esqep-orange;
  border-width: 0.2rem;
  animation: spinner-border 1s linear infinite;
  height: 1rem;
  width: 1rem;
}

.bg-reassured {
  background-color: $reassured-blue;
  &:before {
    background-color: $reassured-blue;
  }
}

.bg-gmfrs {
  // background-color: $gmfrs-red;
  background-color: $gmfrs-black;
  &:before {
    background-color: $gmfrs-black;
  }
}

.bg-mfrs {
  background-color: $mfrs-blue;
  &:before {
    background-color: $mfrs-blue;
  }
}

.bg-pepper {
  background-color: $pepper-red;
  &:before {
    background-color: $pepper-red;
  }
}

.bg-lfrs {
  // background-color: $gmfrs-red;
  background-color: #fe1821 !important;
  &:before {
    background-color: #fe1821 !important;
  }
}

.bg-rnli {
  // background-color: $gmfrs-red;
  background-color: #002663 !important;
  &:before {
    background-color: #002663 !important;
  }
}

.bg-sqeptech {
  background-color: $sqeptech-dark;
  &:before {
    background-color: $sqeptech-dark;
  }
}

.bg-rbfrs {
  background-color: #e2231b !important;
  &:before {
    background-color: #e2231b !important;
  }
}

.bg-getinge {
  background-color: $getinge-blue;
  &:before {
    background-color: $getinge-blue;
  }
}

.bg-aptiv {
  background-color: #191919 !important;
  &:before {
    background-color: #191919 !important;
  }
}

.bg-hilton {
  background-color: #418fde !important;
  &:before {
    background-color: #418fde !important;
  }
}

.bg-plessey {
  background-color: #5279bb !important;
  &:before {
    background-color: #5279bb !important;
  }
}

.bg-wfrs {
  background-color: #096f48 !important;
  &:before {
    background-color: #096f48 !important;
  }
}

.bg-vivo {
  background-color: #8db458 !important;
  &:before {
    background-color: #8db458 !important;
  }
}

.bg-skansa {
  background-color: #143275 !important;
  &:before {
    background-color: #143275 !important;
  }
}

.bg-ukae {
  background-color: #002f56 !important;
  &:before {
    background-color: #002f56 !important;
  }
}

.bg-offWhite {
  background-color: $sqeptech-offWhite;
}

.bg-sqeptech-cyan {
  background-color: $esqep-cyan;
}

.bg-sqeptech-purple {
  background-color: $esqep-purple;
}

.bg-sqeptech-red {
  background-color: $esqep-red;
}

.bg-sqeptech-orange {
  background-color: $esqep-orange;
}

.bg-sgn {
  background-color: #ea5b0c;
  &:before {
    background-color: #ea5b0c;
  }
}

.bg-biffa {
  background-color: rgb(228, 30, 9);
  &:before {
    background-color: rgb(228, 30, 9);
  }
}

.bg-coal-authority {
  background-color: #2e2e2e;
  &:before {
    background-color: #2e2e2e;
  }
}
.bg-getinge-pilot {
  background-color: #00cc00;
  &:before {
    background-color: #00cc00;
  }
}

.bg-cats-protection {
  background-color: #7a1b72 !important;
  &:before {
    background-color: #7a1b72 !important;
  }
}

.bg-shropshire-fire {
  background-color: #003d6f !important;
  &:before {
    background-color: #003d6f !important;
  }
}

.bg-south-wales-police {
  background-color: #064083 !important;
  &:before {
    background-color: #064083 !important;
  }
}

// ug-alert .fixed-top {
//     top: $navbar-height-top + $esqep-spinner-height;
// }

$enable-rounded: false;
$enable-gradients: true;
// $enable-shadows: true;

$sizes: (
  5: 5%,
  10: 10%,
  15: 15%,
  20: 20%,
  30: 30%,
  40: 40%,
  45: 45%,
);

$card-cap-bg: $white;

$alert-margin-bottom: 0;

.ng-invalid.card.esqep-indicate-validity,
select.ng-invalid.esqep-indicate-validity {
  border-color: $form-feedback-invalid-color;
}

#timeoutWarningModal {
  z-index: $zindex-modal + 5;
}

#timeoutWarningModal.modal-backdrop {
  z-index: $zindex-modal-backdrop + 5;
}

.esqepBtn {
  border: 1px solid $sqeptech-button;
  color: $sqeptech-button;
  background-color: transparent;

  &:hover {
    border: 1px solid $sqeptech-button;
    color: $white;
    background-color: $sqeptech-button;
  }
}

.esqepBtn.active {
  border: 1px solid $sqeptech-button;
  color: $white;
  background-color: $sqeptech-button;
}

.card {
  margin-bottom: 0.25rem;
  margin-top: 0.25rem;
}

.flex-fill {
  flex: 1 1 auto;
}

.page-title {
  & h1 {
    color: $sqeptech-offWhite !important;
  }
  color: $sqeptech-offWhite;
  margin: 1.25rem 0;
}

.homeLink {
  font-size: 2rem;
  text-decoration: none;
  color: $white;

  &:hover {
    color: $sqeptech-button;
  }
}

#top-nav {
  height: $top-nav-height;
  padding: 10px;
  z-index: 1051; //one higher than default z-index of ng-select
}

.container-application:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: -1;
  pointer-events: none;
  height: 240px;
}

.card-title {
  color: #264148;
  font-weight: 500;
  border: none;
  padding-bottom: 0.5rem;
}

.card {
  overflow-y: auto;
  overflow-x: auto;
  margin-bottom: 0.25rem;
  margin-top: 0.25rem;
}

.toggleSideBarBtn {
  color: $white;
  background-color: transparent;
  border: none;
  padding: 0;
  margin-right: 10px;
  align-items: center;
}

.wrapper {
  display: flex;
  width: 100%;
}

.offcanvas-body {
  margin-top: $top-nav-height;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.logout {
  text-decoration: none;
  text-align: center;
  font-size: 1.2rem;
  background: #264148;
  color: white;
}
.btn.logout:hover {
  text-decoration: none;
  text-align: center;
  font-size: 1.2rem;
  background: #264148;
  color: white;
}

.btn-close {
  color: $sqeptech-button;
}

.modal-content {
  border: none;
}

.footer {
  color: $sqeptech-offWhite;
  height: $footer-height;

  a {
    text-decoration: none;
    color: $sqeptech-offWhite;
  }
}

//todo: move it from here
.vertical-align-middle {
  vertical-align: middle;
}

.side-nav-header {
  padding: 10px 20px;
  margin-bottom: 25px;
  height: $top-nav-height;
  display: flex;
  width: 100%;
}

header {
  margin-bottom: 60px;
}

#side-nav {
  position: sticky;
  top: $top-nav-height;
  background-color: $white;
  border-top-right-radius: 0.5rem;
}

.nav-button-container {
  display: flex;
  width: $side-nav-width;
  align-items: center;
}

.navFooter {
  width: $side-nav-width;
}

.main-content {
  margin-bottom: 50px;
}
.nav-link:hover {
  color: #264148;
}

.top-nav-offset {
  z-index: 1052;
}

ngb-typeahead-window.dropdown-menu {
  max-height: 200px;
  overflow-y: scroll;
}

.nav-width {
  width: $side-nav-width;
}

#clientLogo {
  max-height: 40px;
  margin-bottom: 5px;
}

.pageNoSize {
  height: 25px;
}

.custom-popover {
  --bs-popover-max-width: 100%;
  --bs-popover-border-color: var(--bs-primary);
  --bs-popover-header-bg: var(--bs-primary);
  --bs-popover-header-color: var(--bs-white);
  --bs-popover-body-padding-x: 1rem;
  --bs-popover-body-padding-y: 0.5rem;
  --bs-popover-font-size: 0.5rem !important;
}

//align ng-select disabled to default bootstrap disabled form control bg colour
.ng-select.ng-select-disabled > .ng-select-container {
  background-color: #e9ecef !important;
}

.custom-tooltip .tooltip-inner {
  background-color: $sqeptech-dark;
  font-size: 14px;
  color: $sqeptech-offWhite;
}

@mixin table-row-style {
  --bs-table-bg: transparent;
  --bs-table-accent-bg: transparent;
}

.striped-row {
  background-color: var(--bs-table-striped-bg);
  @include table-row-style;
}

.non-curric-row {
  background-color: #e9ecef;
  @include table-row-style;
}

.curric-row {
  background-color: $sqeptech-offWhite;
  @include table-row-style;
}

.blur-text {
  color: transparent !important;
  text-shadow: 0 0 6px var(--bs-primary) !important;
}

.ngxEditorContainer {
  background-color: transparent;
  border: 1px solid #ced4da;
  border-radius: 0.375rem;
  height: auto;

  .NgxEditor__MenuBar {
    border-bottom: 1px solid #ced4da;
    background-color: transparent;
  }

  .NgxEditor {
    border: none;
    background-color: transparent;
  }

  .NgxEditor__Content--Disabled {
    background-color: #e9ecef;
  }

  .pointer {
    cursor: pointer;
  }
}

.xxl .modal-dialog {
  max-width: 90vw;
}

.border-start-danger-thick {
  border-left: 5px solid var(--bs-danger);
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option .highlighted {
  text-decoration: none !important;
}

.dp-hidden {
  width: 0;
  margin: 0;
  border: none;
  padding: 0;
}
.custom-day {
  text-align: center;
  padding: 0.185rem 0.25rem;
  display: inline-block;
  height: 2rem;
  width: 2rem;
}
.custom-day.focused {
  background-color: #e6e6e6;
}
.custom-day.range,
.custom-day:hover {
  background-color: rgb(2, 117, 216);
  color: white;
}
.custom-day.faded {
  background-color: rgba(2, 117, 216, 0.5);
}

ng-select.tableDropdown.is-invalid .ng-select-container {
  border-color: var(--bs-form-invalid-border-color);
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

ng-select.tableDropdown .ng-select-container {
  width: 100%;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5;
  color: var(--bs-body-color);
  appearance: none;
  background-color: var(--bs-body-bg);
  background-clip: padding-box;
  border: var(--bs-border-width) solid var(--bs-border-color) !important;
  border-radius: var(--bs-border-radius);
  transition:
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}

.text-esqep-warning {
  color: #f6921d;
}

.bg-esqep-warning {
  background-color: #f6921d !important;
  color: #264148 !important;
}

.role-details-ai-popover {
  min-width: 50% !important;
  z-index: 1000 !important;
  max-height: 90vh;

  .popover-header {
    background-color: rgba(33, 37, 41, 1);
    color: $sqeptech-offWhite;
  }

  .popover-body {
    color: $sqeptech-dark;
  }
}

.striped-row {
  background-color: #f5f5f5;
}

.helper-text {
  font-weight: 500;
}

.selected-row {
  background-color: #264148;
  td {
    color: white;
  }
}

.cdk-overlay-container {
  z-index: 1100 !important;
}

.mat-mdc-paginator-container {
  justify-content: left !important;
}

.mat-mdc-tab {
  height: auto !important;
}

.mat-calendar-previous-button::after,
.mat-calendar-next-button::after {
  margin: 1.2rem !important;
}

.mat-datepicker-content {
  font-size: 14px !important;
  border: 1px solid lightgray !important;
  background-color: #f5f5f5 !important;
}

.claim-skill-history .mat-expansion-panel-body {
  padding: 0 1rem 1rem 1rem !important;
}

.helper-text {
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #212529bf;
}
